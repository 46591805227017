<template>
  <div>
    <h2>{{$t('Dashboard')}}</h2>
  </div>
</template>
<script>
import axios from '../../axios'
export default {
  data () {
    return {
      captain: {}
    }
  },
  methods: {
    loadCaptain () {
      axios.get('api/v1')
    }
  }
}
</script>